import FormItemDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import SelectUserItem from '@/components/select-user-item/SelectUserItem'
import Status from '@/components/status/Status'
import { useApprovalQuery, useUpdateApplicationMutation, useUpdateApprovalMutation } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import approval from '@/pages/application/content/approval/Approval'
import { Form, Row, Skeleton, Space, Spin } from 'antd'
import { FC } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem, { SelectOptionsType } from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const statusSelectOptions = [
  {
    label: <Status status={'active'} />,
    value: 'active',
  },
  {
    label: <Status status={'pending'} />,
    value: 'pending',
  },
  {
    label: <Status status={'rejected'} />,
    value: 'rejected',
  },
  {
    label: <Status status={'stopped'} />,
    value: 'stopped',
  },
]

const ChangeAgentApprovalStatus: FC = () => {
  const [updateApproval, { loading: updatingApproval }] = useUpdateApprovalMutation()

  const { currentId, setIsModalOpen, currentApprovalItemId } = useGeneralContext()

  const {data, loading} = useApprovalQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {id: currentApprovalItemId},
    skip: !currentApprovalItemId
  })
  const approval = data?.approval?.data as ApprovalEntity

  const currentCard = approval?.attributes?.agents?.find(it => it?.agentProfile?.data?.id === currentId) as ComponentDataAgentInfo

  const onFinish = async (value: { status: EnumComponentdataagentinfoAssignstatus }) => {
    const newAgentsInfo = approval?.attributes?.agents?.map(it => {
      if (it?.agentProfile?.data?.id === currentId) {
        return {
          name: it?.name,
          agentProfile: it?.agentProfile?.data?.id,
          assignStatus: value?.status,
        }
      } else {
        return {
          name: it?.name,
          agentProfile: it?.agentProfile?.data?.id,
          assignStatus: it?.assignStatus,
        }
      }

    }) || []

    approval?.id && await updateApproval({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: approval?.id,
        data: {
          agents: newAgentsInfo,
        },
      },
      onCompleted: () => {
        BlackNotification('Status updated successfully')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(`Error changing status. ${error?.message || ''}`)
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
  }
  const initialValues = {
    status: currentCard?.assignStatus || 'pending',
  }

  const onCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <FormItemDivider title={'Change approval agent status'} subTitle={'Changing you status will change your level of access to a particular application.'} marginTop={0} />
      {loading ? (
        <Skeleton paragraph={{rows: 3}} active={true} />
      ) : (
        <>
          <SelectUserItem isBordered={true} name={currentCard?.agentProfile?.data?.attributes?.fullName} email={currentCard?.agentProfile?.data?.attributes?.primaryEmail} avatar={currentCard?.agentProfile?.data?.attributes?.avatar?.data?.attributes?.url} />
        <Form layout={'vertical'} className={'form-content'} onFinish={onFinish} initialValues={initialValues} style={{marginTop: 24}}>
          <FormItem
            name={'status'}
            type={'select'}
            label={'Assignment status'}
            placeholder={'Select assignment status'}
            selectOptions={statusSelectOptions as SelectOptionsType[]}
          />
          <Row justify={'end'}>
            <Space>
              <Button text={'Cancel'} btnType={'ghost'} onClick={onCancel} loading={updatingApproval} />
              <Button text={'Confirm selection'} btnType={'primary'} htmlType={'submit'} loading={updatingApproval} />
            </Space>
          </Row>
        </Form>
        </>
      )}
    </>
  )
}
export default ChangeAgentApprovalStatus
