import AgentCard from '@/components/agent-card/AgentCard'
import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import TablePlaceholder from '@/components/table-placeholder/TablePlaceholder'
import { useApprovalQuery, useUpdateApplicationMutation, useUpdateApprovalMutation, useUserProfileLazyQuery, useUserProfilesQuery } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import { EASETECH_COMPANY } from '@/utilities/global-variables'
import { message, Row, Skeleton, Space } from 'antd'
import { FC, useState } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Paragraph } = Typography
const AgentAssignToApproval: FC = () => {
  const { user, organization } = useAuth()
  const { setIsModalOpen, currentId, currentApprovalItemId } = useGeneralContext()

  const [updateApproval, { loading: approvalUpdating }] = useUpdateApprovalMutation()
  const { data: appDataQuery, loading: appDataLoading } = useApplicationByUuid()

  const currentApplication = appDataQuery?.applications?.data?.[0] as ApplicationEntity

  const { data: approvalItemData, loading: approvalLoading } = useApprovalQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      id: currentApprovalItemId,
    },
  })
  const approval = approvalItemData?.approval?.data as ApprovalEntity

  // === transferred case:

  const [selectedId, setSelectedId] = useState<string | null>(null)

  const existingAgentsIds = approval?.attributes?.agents?.map(it => it?.agentProfile?.data?.id) || []
  const currentOrganizationCompanies = user?.organization?.data?.attributes?.companies?.data?.map(it => it?.id) || []


  const { data, loading } = useUserProfilesQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: {
        id: { notIn: existingAgentsIds },
        or: [
          {
            profileType: { eq: 'agent' },
            organization: {
              slug: { in: [EASETECH_COMPANY, organization] },
            },
          },
          {
            profileType: { eq: 'external_agent' },
            consultingCompanies: { id: { in: [...currentOrganizationCompanies] } },
          },
        ],
      },
    },
  })
  const availableAgents = data?.userProfiles?.data as UserProfileEntity[]

  const onCancel = () => {
    console.log('cancel', setIsModalOpen)
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    const selectedAgent = availableAgents?.find(it => it?.id === selectedId)
    // ===> When assign inside approval item

      const existAgentInfoSanitized = approval?.attributes?.agents?.map(it => ({
        agentProfile: it?.agentProfile?.data?.id,
        assignStatus: it?.assignStatus,
        name: it?.name,
      })) || []
      const newAgentsInfoSetForSelectedApproval = [...existAgentInfoSanitized, {
        agentProfile: selectedId,
        name: selectedAgent?.attributes?.fullName,
      }]

      approval?.id && await updateApproval({
        variables: {
          id: approval?.id,
          data: {
            agents: newAgentsInfoSetForSelectedApproval,
          },
        },
        onError: (e) => {
          message.error(e.message)
          setIsModalOpen(false)
        },
        onCompleted: () => {
          BlackNotification(`Agent assigned successfully to ${approval?.attributes?.company?.data?.attributes?.name} approval item`)
          setIsModalOpen(false)
        },
        refetchQueries: 'active',
      })
  }

  const onCardClick = (id: string) => {
    setSelectedId(id)
  }
  return (
    <>
      <FormItemDivider
        marginBottom={12}
        title={'Assign agent to a current approval'}
        subTitle={'Click on card to select agent'}
      />
      <Paragraph>{'Upon assignment, agents will receive a notification. By default, assigned agents will be given a status of \'Pending.\' Agents must confirm the assignment on their end, at which point their status will be updated to \'Active.\''}</Paragraph>
      {loading ? <Skeleton paragraph={{ rows: 5 }} active={true} /> : (
        <Row className={'agents-cards-container'}>
          <Space direction={'vertical'} style={{ width: '100%' }}>
            {availableAgents?.length !== 0 && availableAgents?.map(it => (
              <AgentCard
                key={it?.id}
                userData={it}
                isSelected={it?.id === selectedId}
                onCardClick={onCardClick}
                isSelectable={true}
                loading={loading || appDataLoading}
              />
            ))}
          </Space>
        </Row>
      )}
      {(!loading && !availableAgents?.length) && (
        <Row justify={'center'}>
          <TablePlaceholder title={'No agents available.'} text={' Please, register new agent or contact our platform support team.'} imageSize={'small'} />
        </Row>
      )}
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        {availableAgents?.length !== 0 && (
          <>
            <Button text={'Cancel'} btnType={'text'} onClick={onCancel} loading={approvalUpdating || approvalLoading} />
            <Button text={'Assign selected agent'} btnType={'primary'} onClick={onConfirm} loading={approvalUpdating || approvalLoading}
                    disabled={!selectedId} />
          </>
        )}
      </Row>
    </>
  )
}
export default AgentAssignToApproval
