import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { WebsiteLoader } from '@/components/layout/WebsiteLoader'
import { useApplicationQuery, useUpdateApplicationMutation } from '@/graphql'
import { Row, Space } from 'antd'
import React, { FC } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const UnassignAppAgentConfirmation: FC = () => {
  const { setIsModalOpen, currentApplicationId, currentId } = useGeneralContext()
  const [updateApplication, { loading: applicationUpdating }] = useUpdateApplicationMutation()

  const { data, loading: appDataLoading } = useApplicationQuery({
    variables: { id: currentApplicationId },
    fetchPolicy: globalFetchPolicy,
  })
  const application = data?.application?.data

  const onCancel = () => {
    setIsModalOpen(false)
  }

  const agentsInfoData = application?.attributes?.agentsInfo as ComponentDataAgentInfo[]

  const onConfirm = async () => {
    const newAgentsInfo = agentsInfoData?.map(it => {
      if (it?.agentProfile?.data?.id !== currentId) {
        return {
          agentProfile: it?.agentProfile?.data?.id,
          assignStatus: it?.assignStatus,
        }
      }
      return null
    }).filter(it => it) || []

    await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: application?.id || '',
        data: {
          agentsInfo: newAgentsInfo,
        },
      },
      onCompleted: () => {
        BlackNotification('Agent unassigned successfully from the current application')
        setIsModalOpen(false)
      },
      onError: (error) => {
        setIsModalOpen(false)
        console.log(error)
        BlackNotification(`Error server request. ${error.message}`)
      },
      refetchQueries: 'active',
    })
  }
  if (appDataLoading) return <WebsiteLoader />
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Unassign agent'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>
        {`You are going to unassign selected agent from the current application `}
        <Text size={'lg'} weight={'w700'}>{application?.attributes?.title}</Text>
      </Paragraph>
      <Row justify={'end'}>
        <Space>
          <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
          <Button text={'Confirm'} btnType={'primary'} onClick={onConfirm} loading={appDataLoading || applicationUpdating} />
        </Space>
      </Row>
    </>
  )
}
export default UnassignAppAgentConfirmation
