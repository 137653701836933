import AgentCard from '@/components/agent-card/AgentCard'
import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import TablePlaceholder from '@/components/table-placeholder/TablePlaceholder'
import { useUpdateApplicationMutation, useUserProfilesQuery } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import { EASETECH_COMPANY } from '@/utilities/global-variables'
import { Row, Skeleton, Space } from 'antd'
import { FC, useState } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Paragraph } = Typography
const AgentAssignToApp: FC = () => {
  const { user, organization } = useAuth()
  const { setIsModalOpen, currentId } = useGeneralContext()

  const [updateApplication, { loading: applicationUpdating }] = useUpdateApplicationMutation()
  const { data: appDataQuery, loading: appDataLoading } = useApplicationByUuid()

  const currentApplication = appDataQuery?.applications?.data?.[0] as ApplicationEntity

  const [selectedId, setSelectedId] = useState<string | null>(null)

  const existingAgentsIds = currentApplication?.attributes?.agentsInfo?.map(it => it?.agentProfile?.data?.id) || []
  const existAgentsInfo = currentApplication?.attributes?.agentsInfo as ComponentDataAgentInfo[]
  const currentOrganizationCompanies = user?.organization?.data?.attributes?.companies?.data?.map(it => it?.id) || []

  const { data, loading } = useUserProfilesQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: {
        id: { notIn: existingAgentsIds },
        or: [
          {
            profileType: { eq: 'agent' },
            organization: {
              slug: { in: [EASETECH_COMPANY, organization] },
            },
          },
          {
            profileType: { eq: 'external_agent' },
            consultingCompanies: { id: { in: [...currentOrganizationCompanies] } },
          },
        ],
      },
    },
  })
  const availableAgents = data?.userProfiles?.data as UserProfileEntity[]

  const onCancel = () => {
    console.log('cancel', setIsModalOpen)
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    const selectedAgent = availableAgents?.find(it => it?.id === selectedId)
    const existAgentInfoSanitized = existAgentsInfo?.map(it => ({
      agentProfile: it?.agentProfile?.data?.id,
      assignStatus: it?.assignStatus,
      name: it?.name,
    }))

    const newAgentsInfoSet = [...existAgentInfoSanitized, {
      agentProfile: selectedId,
      name: selectedAgent?.attributes?.fullName,
    }]

    await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentId,
        data: {
          agentsInfo: newAgentsInfoSet,
        },
      },
      onCompleted: () => {
        BlackNotification('Agent assigned successfully to the current application')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(`Error while trying to assign agent. ${error.message}`)
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })


  }

  const onCardClick = (id: string) => {
    setSelectedId(id)
  }
  return (
    <>
      <FormItemDivider
        marginBottom={12}
        title={'Assign agent to application'}
        subTitle={'Click on card to select agent'}
      />
      <Paragraph>{'Upon assignment, agents will receive a notification. By default, assigned agents will be given a status of \'Pending.\' Agents must confirm the assignment on their end, at which point their status will be updated to \'Active.\''}</Paragraph>
      {loading ? <Skeleton paragraph={{ rows: 5 }} active={true} /> : (
        <Row className={'agents-cards-container'}>
          <Space direction={'vertical'} style={{ width: '100%' }}>
            {availableAgents?.length !== 0 && availableAgents?.map(it => (
              <AgentCard
                key={it?.id}
                userData={it}
                isSelected={it?.id === selectedId}
                onCardClick={onCardClick}
                isSelectable={true}
                loading={loading || appDataLoading}
              />
            ))}
          </Space>
        </Row>
      )}
      {(!loading && !availableAgents?.length) && (
        <Row justify={'center'}>
          <TablePlaceholder title={'No agents available.'} text={' Please, register new agent or contact our platform support team.'} imageSize={'small'} />
        </Row>
      )}
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        {availableAgents?.length !== 0 && (
          <>
            <Button text={'Cancel'} btnType={'text'} onClick={onCancel} loading={applicationUpdating} />
            <Button text={'Assign selected agent'} btnType={'primary'} onClick={onConfirm} loading={applicationUpdating}
                    disabled={!selectedId} />
          </>
        )}
      </Row>
    </>
  )
}
export default AgentAssignToApp
