import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useApprovalQuery, useUpdateApprovalMutation } from '@/graphql'
import { Row, Skeleton, Space } from 'antd'
import { FC } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const UnassignApprovalAgentConfirmation: FC = () => {
  const { setIsModalOpen, currentApprovalItemId, currentId } = useGeneralContext()
  const [updateApproval, { loading: updatingApproval }] = useUpdateApprovalMutation()

  const { data: approvalData, loading: approvalDataLoading } = useApprovalQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentApprovalItemId },
  })
  const approval = approvalData?.approval?.data as ApprovalEntity
  const approvalCompanyName = approval?.attributes?.company?.data?.attributes?.name
  const onCancel = () => {
    setIsModalOpen(false)
  }

  const agentsInfoData = approval?.attributes?.agents as ComponentDataAgentInfo[]

  const onConfirm = async () => {
    const newAgentsInfo = agentsInfoData?.map(it => {
      if (it?.agentProfile?.data?.id !== currentId) {
        return {
          name: it?.name,
          agentProfile: it?.agentProfile?.data?.id,
          assignStatus: it?.assignStatus,
        }
      }
      return null
    }).filter(it => it) || []

    await updateApproval({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentApprovalItemId || '',
        data: {
          agents: newAgentsInfo,
        },
      },
      onCompleted: () => {
        BlackNotification('Agent unassigned successfully from the current approval')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(`Error approval item updating. ${error.message}`)
      },
      refetchQueries: 'active',
    })
  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Unassign agent'}
      </Text>
      {approvalDataLoading ? <Skeleton paragraph={{ rows: 4 }} /> : (
        <>
          <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>
            {`You are going to unassign selected agent from the current approval `}
            <Text size={'lg'} weight={'w700'}>{approvalCompanyName}</Text>
          </Paragraph>
          <Row justify={'end'}>
            <Space>
              <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
              <Button text={'Confirm'} btnType={'primary'} onClick={onConfirm} loading={approvalDataLoading || updatingApproval} />
            </Space>
          </Row>
        </>
      )}

    </>
  )
}
export default UnassignApprovalAgentConfirmation
